import React from 'react'

const PracticesDetail = () => {
  return (
    <div>PracticesDetail</div>
  )
}

export default PracticesDetail
// import React, { useEffect } from "react";
// import { useState } from "react";

// import { useNavigate, useParams } from "react-router-dom";

// import { Divider, Paper, TextField } from "@mui/material";
// import Table from "@mui/material/Table";
// import Button from "@mui/material/Button";
// import TableRow from "@mui/material/TableRow";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import Container from "@mui/material/Container";
// import TableContainer from "@mui/material/TableContainer";

// import useAxiosPrivate from "../../hooks/useAxiosPrivate";
// import useGetElemDataById from "../../hooks/useGetElemDataById";

// import BackButton from "../BackButton";

// function createData(name, value) {
//   return { name, value };
// }

// const CompaniesDetail = () => {
//   const navigate = useNavigate();
//   const { idCompany } = useParams();
//   const axiosPrivate = useAxiosPrivate();
//   const [edit, setEdit] = useState(false);
//   const companyData = useGetElemDataById("Companies", idCompany);
//   console.log(companyData.name);
//   const rows = [
//     createData("Nome", companyData.name),
//     createData("Codice compagnia", companyData.company_code),
//     createData("Codice IVASS", companyData.ivass_code),
//     createData("Email", companyData.email),
//   ];

//   const [data, setData] = useState({
//     name: "",
//     company_code: "",
//     ivass_code: "",
//     email: "",
//   });

//   useEffect(() => {
//     setData({
//       ...data,
//       name: companyData.name,
//       company_code: companyData.company_code,
//       ivass_code: companyData.ivass_code,
//       email: companyData.email,
//     });
//   }, [
//     companyData.name,
//     companyData.company_code,
//     companyData.ivass_code,
//     companyData.email,
//     data
//   ]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       await axiosPrivate.put("/Companies/update_by_id", {
//         id: companyData.id,
//         new_values: {
//           name: data.name,
//           company_code: data.company_code,
//           ivass_code: data.ivass_code,
//           email: data.email,
//         }
//       });

//       window.alert("Compagnia aggiornata con successo!");
//       navigate(-1);;
//     } catch (err) {
//       if (!err?.response) {
//         window.alert("Errore. Riprova");
//       } else if (err.response?.status === 400) {
//         window.alert("Username o password mancante!");
//       } else if (err.response?.status === 401) {
//         window.alert("Non sei autorizzato");
//       } else {
//         window.alert("Errore. Riprova");
//       }
//       // errRef.current.focus();
//     }
//   };

//   return (
//     <React.Fragment>
//       <Container component={Paper} maxWidth={"sm"}>
//         <br />
//         <BackButton />
//         <h1>{companyData.name}</h1>
//         <br />
//         <TableContainer>
//           <Table aria-label="simple table">
//             {!edit ? (
//               <TableBody>
//                 {rows.map((row) => (
//                   <TableRow
//                     key={row.name}
//                     sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                   >
//                     <TableCell
//                       component="th"
//                       scope="row"
//                       sx={{ fontSize: 20, fontWeight: "bold" }}
//                     >
//                       {row.name}
//                     </TableCell>
//                     <TableCell align="right" sx={{ fontSize: 20 }}>
//                       {row.value}
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             ) : (
//               <TableBody>
//                 <TableRow
//                   key="Nome"
//                   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                 >
//                   <TableCell
//                     component="th"
//                     scope="row"
//                     sx={{ fontSize: 20, fontWeight: "bold" }}
//                   >
//                     Nome
//                   </TableCell>
//                   <TextField
//                     type="text"
//                     align="right"
//                     value={data.name}
//                     onChange={(e) => {
//                       setData({ ...data, name: e.target.value });
//                     }}
//                     sx={{ fontSize: 20, my: 1, width: "100%" }}
//                   />
//                 </TableRow>
//                 <TableRow
//                   key="Codice compagnia"
//                   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                 >
//                   <TableCell
//                     component="th"
//                     scope="row"
//                     sx={{ fontSize: 20, fontWeight: "bold" }}
//                   >
//                     Codice compagnia
//                   </TableCell>
//                   <TextField
//                     type="text"
//                     align="right"
//                     value={data.company_code}
//                     onChange={(e) => {
//                       setData({ ...data, company_code: e.target.value });
//                     }}
//                     sx={{ fontSize: 20, my: 1, width: "100%" }}
//                   />
//                 </TableRow>
//                 <TableRow
//                   key="Codice IVASS"
//                   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                 >
//                   <TableCell
//                     component="th"
//                     scope="row"
//                     sx={{ fontSize: 20, fontWeight: "bold" }}
//                   >
//                     Codice IVASS
//                   </TableCell>
//                   <TextField
//                     type="text"
//                     align="right"
//                     value={data.ivass_code}
//                     onChange={(e) => {
//                       setData({ ...data, ivass_code: e.target.value });
//                     }}
//                     sx={{ fontSize: 20, my: 1, width: "100%" }}
//                   />
//                 </TableRow>
//                 <TableRow
//                   key="Email"
//                   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                 >
//                   <TableCell
//                     component="th"
//                     scope="row"
//                     sx={{ fontSize: 20, fontWeight: "bold" }}
//                   >
//                     Email
//                   </TableCell>
//                   <TextField
//                     type="email"
//                     align="right"
//                     value={data.email}
//                     onChange={(e) => {
//                       setData({ ...data, email: e.target.value });
//                     }}
//                     sx={{ fontSize: 20, my: 1, width: "100%" }}
//                   />
//                 </TableRow>
//               </TableBody>
//             )}
//           </Table>
//         </TableContainer>
//         <Divider sx={{ my: 2 }} />
//         {!edit ? (
//           <React.Fragment>
//             <Button
//               variant="contained"
//               sx={{ mx: 2, width: "40%" }}
//               onClick={() => setEdit(true)}
//             >
//               Modifica
//             </Button>
//             <Button
//               color="error"
//               variant="contained"
//               sx={{ mx: 2, width: "40%" }}
//             >
//               Elimina
//             </Button>
//           </React.Fragment>
//         ) : (
//           <React.Fragment>
//             <Button
//               variant="contained"
//               sx={{ mx: 2, width: "40%" }}
//               onClick={(e) => handleSubmit(e)}
//             >
//               Salva
//             </Button>
//             <Button
//               color="error"
//               variant="contained"
//               sx={{ mx: 2, width: "40%" }}
//               onClick={() => {
//                 setEdit(false);
//                 setData({
//                   name: companyData.name,
//                   company_code: companyData.company_code,
//                   ivass_code: companyData.ivass_code,
//                   email: companyData.email,
//                 });
//               }}
//             >
//               Annulla
//             </Button>
//           </React.Fragment>
//         )}
//         <br />
//         <br />
//       </Container>
//     </React.Fragment>
//   );
// };

// export default CompaniesDetail;
