import * as React from "react";
import { useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
} from "@mui/material";

import { axiosAuth } from "../../api/axios";
import useAuth from "../../hooks/auth/useAuth";
import Copyright from "../../components/layout/Copyright";
import LogoPublic from "../../components/layout/LogoPublic";
import useGetUserCompleteInfo from "../../hooks/users/useGetUserCompleteInfo";
import { isMobile } from "react-device-detect";

export default function SignInSide() {
  const { setAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const navigate = useNavigate();
  const userRole = useGetUserCompleteInfo("", "login");

  if (userRole.user && userRole.user.role) {
    // se utente è già loggato lo reindirizzo alla sua home
    switch (userRole.user.role) {
      case "Admin":
        navigate("/admin", { replace: true });
        break;

      case "Alphega_Centrale":
        navigate("/alphega_centrale/alphega_home", { replace: true });
        break;

      case "Farmacia":
        navigate("/pharmacies_home", { replace: true });
        break;

      // case "Controller":
      //   navigate("/controller", { replace: true });
      //   break;

      default:
        break;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axiosAuth.post(
        "/login",
        JSON.stringify({ email: email, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(JSON.stringify(response?.data));
      const accessToken = response?.data?.result.accessToken;
      const role = response?.data?.result.role;
      console.log(accessToken);
      setAuth({ email, pwd, accessToken });
      setEmail("");
      setPwd("");
      switch (role) {
        case "6957":
          navigate("/admin", { replace: true });
          break;

        case "4719":
          navigate("/alphega_centrale/alphega_home", { replace: true });
          break;

        default:
          navigate("*", { replace: true });
          break;
      }
    } catch (err) {
      if (!err?.response) {
        window.alert(
          "Errore. Controlla che le credenziali inserite siano corrette"
        );
      } else if (err.response?.status === 400) {
        window.alert("Email o password mancante!");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Login fallito");
      }
    }
  };

  return (
    <Container component={Paper}>
      <Box
        sx={{
          my: 2,
          mx: isMobile ? "auto" : 40,
          py: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LogoPublic />
      </Box>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 1, mx: isMobile ? "auto" : 40 }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={email}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          onChange={(e) => setPwd(e.target.value)}
          name="password"
          label="Password"
          type="password"
          id="password"
          value={pwd}
          autoComplete="current-password"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Accedi
        </Button>
        {/* <Grid2 container>
                <Grid2 item xs>
                  <Link href="login/reset_password" variant="body2">
                    Password dimenticata?
                  </Link>
                </Grid2>
              </Grid2> */}
      </Box>
      <Copyright />
    </Container>
  );
}
