import RolesDetail from "../../../../components/roles/RolesDetail";
import NavBar from "../../../../components/navbar/NavBar";
import { Box } from "@mui/material";

const AdminRolesDetail = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <NavBar value="6957" />
      <Box
        component="main"
        pt={"75px"}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <RolesDetail user="admin" />
      </Box>
    </Box>
  );
};

export default AdminRolesDetail;
