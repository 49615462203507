import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "./components/layout/Layout";
import * as PublicPages from "./pages/public/Pages";
import RequireAuth from "./components/auth/RequireAuth";
import * as AdminPages from "./pages/private/admin/Pages";
import * as CommonPages from "./pages/private/common/Pages";
import * as PharmaciesPages from "./pages/private/pharmacies/Pages";
import * as AlphegaCentralePages from "./pages/private/alphega_centrale/Pages";

import "./App.css";

const ROLES = {
  Generic: "5761",
  Admin: "6957",
  Alphega_Centrale: "4719",
  Farmacia: "8632",
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/*\\\\\\\\ public routes \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
        <Route path="" element={<Navigate to="/login" />} />
        <Route path="login" element={<PublicPages.Login />} />

        <Route path="*" element={<PublicPages.Missing />} />
        <Route path="unauthorized" element={<PublicPages.Unauthorized />} />

        <Route path="area_cliente" element={<Layout />}>

          <Route
            path="accesso/:key"
            element={<PublicPages.PharmaciesAccessRedirect />}
          />
          <Route
            path="accesso/:key/richiedi_nuovo_link"
            element={<PublicPages.PharmaciesNewAccessLinkRequest />}
          />
          <Route
            path="accesso_comunicazione/:key"
            element={<PublicPages.PharmaciesAccessToPromotionRedirect />}
          />
          
        </Route>

        {/*\\\\\\\\ private routes \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
        {/* common pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
        <Route path="profilo" element={<Layout />}>
          <Route element={<RequireAuth allowedRoles={ROLES.Generic} />}>
            <Route
              path=""
              element={<CommonPages.Profile action={{ value: "no-edit" }} />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={ROLES.Generic} />}>
            <Route
              path="modifica"
              element={<CommonPages.Profile action={{ value: "edit" }} />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={ROLES.Generic} />}>
            <Route
              path="modifica_password"
              element={<CommonPages.Profile action={{ value: "edit-psw" }} />}
            />
          </Route>
        </Route>

        {/* admin pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}
        <Route path="admin" element={<Layout />}>
          <Route element={<RequireAuth allowedRoles={ROLES.Admin} />}>
            <Route path="" element={<AdminPages.AdminHome />} />
          </Route>

          <Route path="roles" element={<Layout />}>
            <Route element={<RequireAuth allowedRoles={ROLES.Admin} />}>
              <Route path="" element={<AdminPages.AdminRolesList />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={ROLES.Admin} />}>
              <Route
                path="detail/:idRole"
                element={<AdminPages.AdminRolesDetail />}
              />
            </Route>
          </Route>
        </Route>
        {/* pharmacies pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}

        <Route
          element={
            <RequireAuth allowedRoles={ROLES.Admin + ";" + ROLES.Farmacia} />
          }
        >
          <Route path="pharmacies_promotions" element={<Layout />}>
            <Route path="" element={<PharmaciesPages.PharmaciesPromotions />} />

            <Route
              path=":idPromotion"
              element={<PharmaciesPages.PharmaciesPromotionsDetail />}
            />
          </Route>

          <Route
            path="pharmacies_upload"
            element={<PharmaciesPages.PharmaciesUpload />}
          />
          <Route
            path="pharmacies_home"
            element={<PharmaciesPages.PharmaciesHome />}
          />
          <Route
            path="profile"
            element={<PharmaciesPages.PharmaciesProfile />}
          />
        </Route>

        {/* alphega centrale pages \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/}

        <Route path="alphega_centrale" element={<Layout />}>
          <Route
            element={
              <RequireAuth
                allowedRoles={ROLES.Alphega_Centrale + ";" + ROLES.Admin}
              />
            }
          >
            <Route
              path="alphega_home"
              element={<AlphegaCentralePages.AlphegaHome />}
            />

            <Route path="alphega_promotions" element={<Layout />}>
              <Route
                path=""
                element={<AlphegaCentralePages.AlphegaPromotions />}
              />

              <Route
                path=":idPromotion"
                element={<AlphegaCentralePages.AlphegaPromotionsDetail />}
              />

              <Route
                path=":idPromotion/alphega_promotion_assignment/"
                element={<AlphegaCentralePages.AlphegaPromotionAssignment />}
              />
            </Route>

            <Route path="alphega_users" element={<Layout />}>
              <Route path="" element={<AlphegaCentralePages.AlphegaUsers />} />

              <Route path="alphega_create_user" element={<AlphegaCentralePages.AlphegaCreateUser />} />

              <Route
                path="deactivated"
                element={<AlphegaCentralePages.AlphegaUsersDeactivated />}
              />

              <Route
                path=":idUser"
                element={<AlphegaCentralePages.AlphegaUsersDetail />}
              />

              <Route
                path="deactivated/:idUser"
                element={<AlphegaCentralePages.AlphegaUsersDetail />}
              />

              <Route
                path=":idUser/:idPromotion"
                element={<AlphegaCentralePages.AlphegaPromotionsDetail />}
              />
            </Route>

            <Route
              path="alphega_create_promotion"
              element={<AlphegaCentralePages.AlphegaCreatePromotion />}
            />

            <Route
              path="profile"
              element={<AlphegaCentralePages.AlphegaProfile />}
            />

            <Route
              path="files"
              element={<AlphegaCentralePages.AlphegaFiles />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
